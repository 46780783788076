import React from 'react';
import {Message} from 'src/types';
import * as R from 'ramda';
import AnalyticsManager, {EVENTS} from 'src/analytics/AnalyticsManager';
import {
  DIRECTION_NEXT,
  INDIVIDUAL_MESSAGE,
  NEXT_SEARCH,
  SCROLL_DOWN,
  SPLIT_OLD_MESSAGE_LIMIT,
} from 'src/constants/messageTypes';
import OptimizedFetchMessages from '../../../../../../gql/query/OptimizedFetchMessages';

interface Props {
  fetchMore: any;
  chatId: string;
  messages: Message[];
}

export default ({
  fetchMore,
  chatId,
  messages,
}: Props): {
  potentiallyMoreMessageToFetch: boolean;
  fetchMoreRequest: (
    searchLastMessageId?: number | string | null,
    scrollDirection?: string | null,
    direction?: string | null,
    functionName?: string | null,
  ) => Promise<any>;
} => {
  const [potentiallyMoreMessageToFetch, setPotentiallyMoreMessageToFetch] = React.useState<boolean>(true);

  const isFetching = React.useRef(false);

  const fetchMoreRequest = async (
    searchLastMessageId: string | number | null | undefined = null,
    scrollDirection: string | null = null,
    direction: string | null = null,
    functionName: string | null = null,
  ) => {
    if (isFetching.current) return;

    isFetching.current = true;
    const lastMessage: Message = messages[messages.length - 1];
    const lastMessageId = searchLastMessageId ? searchLastMessageId : lastMessage ? lastMessage.id : 0;
    let combineMessages = [];

    const fetchMoreVariables: {
      chatId: string;
      messagesContinuationId: string | number;
    } = {
      chatId,
      messagesContinuationId: lastMessageId,
    };
    if (scrollDirection === SCROLL_DOWN) {
      fetchMoreVariables['direction'] = DIRECTION_NEXT;
    }
    try {
      await fetchMore({
        query: OptimizedFetchMessages,
        variables: fetchMoreVariables,
        updateQuery: (previousResult, {fetchMoreResult}) => {
          if (!R.isEmpty(previousResult)) {
            let oldMessages: Message[];
            let newMessages: Message[];
            const messageLens = R.lensPath(['chat', 'messages', 'messages']);

            oldMessages = R.view(messageLens, previousResult);
            newMessages = R.view(messageLens, fetchMoreResult);
            isFetching.current = false;

            // no need to update
            if (newMessages && newMessages.length === 0) {
              setPotentiallyMoreMessageToFetch(false);
              return previousResult;
            }

            // TODO-bug: when backend implemented two-way pagination, dup continues message was added
            // in future diff new and old to get rid of the dups
            if (oldMessages.slice(-1)[0] && newMessages[0] && oldMessages.slice(-1)[0].id === newMessages[0].id) {
              newMessages.shift();
            }

            let concatenatedMessages: any[] = [];
            if (searchLastMessageId) {
              let modifiedOldMessages;
              if (functionName === NEXT_SEARCH) {
                const cloneOldMessages = [...oldMessages];
                if (direction === DIRECTION_NEXT) {
                  setPotentiallyMoreMessageToFetch(true);

                  let updatedMessage;
                  updatedMessage = cloneOldMessages.splice(0, SPLIT_OLD_MESSAGE_LIMIT);
                  modifiedOldMessages = updatedMessage;
                } else {
                  oldMessages.splice(0, oldMessages.length - SPLIT_OLD_MESSAGE_LIMIT);
                  modifiedOldMessages = oldMessages;
                }
              } else if (functionName === INDIVIDUAL_MESSAGE) {
                if (!potentiallyMoreMessageToFetch) {
                  setPotentiallyMoreMessageToFetch(true);
                }
                modifiedOldMessages = [...newMessages];
              } else {
                modifiedOldMessages = [...oldMessages, ...newMessages];
              }

              var allMessages = [...newMessages, ...modifiedOldMessages]
                .sort(function (a, b) {
                  return parseInt(a.id) - parseInt(b.id);
                })
                .reverse();

              const uniqueIds = new Set();
              const uniqueMessages = allMessages.filter((element) => {
                const isDuplicate = uniqueIds.has(element.id);
                uniqueIds.add(element.id);
                if (!isDuplicate) {
                  return true;
                }

                return false;
              });
              concatenatedMessages = uniqueMessages;
              combineMessages['allMessages'] = concatenatedMessages;
            } else {
              concatenatedMessages = [...oldMessages, ...newMessages];
            }

            combineMessages['status'] = true;
            combineMessages['previousMessages'] = oldMessages;
            combineMessages['currentMessages'] = newMessages;

            const result = R.set(messageLens, concatenatedMessages, previousResult);

            AnalyticsManager.applyAnalytics({
              eventName: EVENTS.messageRead,
              params: {
                chat_id: chatId,
                message_count: concatenatedMessages.length,
              },
            });

            return result;
          }
        },
      });
      if (searchLastMessageId) {
        return Promise.resolve(combineMessages);
      } else {
        return Promise.resolve('done');
      }
    } catch (e) {
      // TODO-bug: happening when changing chat while refetching
      // https://github.com/apollographql/apollo-client/issues/4114
      console.error(e);
      return Promise.reject('update fetch more failed');
    }
  };

  return {
    potentiallyMoreMessageToFetch,
    fetchMoreRequest,
  };
};
